import BaseReducer from "../BaseReducer";
import UsersAction from "./usersAction";

export default class UsersReducer extends BaseReducer {
  initialState = {
    users: [],
    pageCount: 0,
    currentPage: 0,
    sortBy: 'username',
    sorted: 'ASC',
    loading: true,
    totalElements: [],
    investigationsList: [],
    permissions: {},
    userQuota: {},
    groups: [],
    activeGroup: null,
  };

  /* SET ANY PROPERTY TO Users store*/
  [UsersAction.UPDATE_USER_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    }
  }

  [UsersAction.SET_INVESTIGATIONS_LIST](state, action){
    return {
      ...state,
      investigationsList: action.payload
    }
  }

  [UsersAction.SET_USER_LIST](state, action) {
    return {
      ...state,
      users: action.payload.data.content,
      pageCount: action.payload.data.totalPages,
      currentPage: action.payload.data.number,
      totalElements: action.payload.data.totalElements
    };
  }

  [UsersAction.SET_USERS_LOADING](state, action) {
    return {
      ...state,
      loading: action.payload,
    };
  }
}
