import config from "../config/config";
import noAvatar from "../assets/img/dummy/noavatar1.png";

export function imageByIndex(id, internal = false) {
  if (id === "-1") {
    return noAvatar;
  }
  return id ? `${config.api_image}/${id}` : null;
}

export function imageByMMIndex(id, internal = false) {
  return id ? `${config.api_mm_image}/${id}` : null;
}