import ActionUtility from "../ActionUtility";

export default class UsersAction {
  static GET_USER_LIST = "UsersAction.GET_USER_LIST";
  static SET_USER_LIST = "UsersAction.SET_USER_LIST";
  static CREATE_USER = "UsersAction.CREATE_USER";
  static EDIT_USER = "UsersAction.EDIT_USER";
  static EDIT_USER_NAME = "UsersAction.EDIT_USER_NAME";
  static DELETE_USER = "UsersAction.DELETE_USER";
  static SET_USERS_LOADING = "UsersAction.SET_USERS_LOADING";
  static UPDATE_QUOTA = "UserAction.UPDATE_QUOTA";

  static GET_INVESTIGATIONS_LIST = "UserAction.GET_INVESTIGATIONS_LIST";
  static SET_INVESTIGATIONS_LIST = "UserAction.SET_INVESTIGATIONS_LIST";

  static ASSIGN_INVESTIGATION = "UserAction.ASSIGN_INVESTIGATION";

  static GET_QUICK_USER_LIST = "UsersAction.GET_QUICK_USER_LIST";
  static GET_USER_INFO = "UsersAction.GET_USER_INFO";
  static UPDATE_USER_STATE = "UsersAction.UPDATE_USER_STATE";

  static GET_USER_QUOTA = "UsersAction.GET_USER_QUOTA";

  static GET_GROUPS_LIST = "UsersAction.GET_GROUPS";
  static CREATE_GROUP = "UsersAction.CREATE_GROUP";
  static EDIT_GROUP = "UsersAction.EDIT_GROUP";
  static DELETE_GROUP = "UsersAction.DELETE_GROUP";
  static DELETE_GROUP_FOREVER = "UsersAction.DELETE_GROUP_FOREVER";
  static ACTIVATE_GROUP = "UsersAction.ACTIVATE_GROUP";

  static GET_PERMISSIONS_ALL = "UsersAction.GET_PERMISSIONS_ALL";
  static GET_ADMIN_LIST = "UsersAction.GET_ADMIN_LIST";
  static CREATE_ADMIN = "UsersAction.CREATE_ADMIN";
  static EDIT_ADMIN = "UsersAction.EDIT_ADMIN";
  static EDIT_ADMIN_NAME = "UsersAction.EDIT_ADMIN_NAME";
  static DELETE_ADMIN = "UsersAction.DELETE_ADMIN";


  static setInvestigationsList(data) {
    return ActionUtility.createAction(
      UsersAction.SET_INVESTIGATIONS_LIST,
      data
    );
  }

  static assignInvestigation(data, callback, userName) {
    return ActionUtility.createAction(UsersAction.ASSIGN_INVESTIGATION, {
      data,
      callback,
      userName
    });
  }

  static getInvestigaionsList(userData) {
    return ActionUtility.createAction(
      UsersAction.GET_INVESTIGATIONS_LIST,
      userData
    );
  }

  static updateQuota(data) {
    return ActionUtility.createAction(UsersAction.UPDATE_QUOTA, data);
  }

  static getUserList(data) {
    //console.log('action', data);
    return ActionUtility.createAction(UsersAction.GET_USER_LIST, data);
  }

  static setUserList(page) {
    return ActionUtility.createAction(UsersAction.SET_USER_LIST, page);
  }

  static createUser(user, callback) {
    return ActionUtility.createAction(UsersAction.CREATE_USER, {user, callback});
  }

  static editUser(editedUser, callback) {
    return ActionUtility.createAction(UsersAction.EDIT_USER, {editedUser, callback});
  }

  static editUserName(id, newInfo, currentPage = 0, callback) {
    return ActionUtility.createAction(UsersAction.EDIT_USER_NAME, {id, newInfo, currentPage, callback});
  }

  static deleteUser(userData, callback) {
    return ActionUtility.createAction(UsersAction.DELETE_USER, {userData, callback});
  }

  static setUsersLoading(state) {
    return ActionUtility.createAction(UsersAction.SET_USERS_LOADING, state);
  }

  static getQuickUserList(callback) {
    return ActionUtility.createAction(
      UsersAction.GET_QUICK_USER_LIST,
      callback
    );
  }

  static getUserInfo(callback) {
    return ActionUtility.createAction(UsersAction.GET_USER_INFO, callback);
  }

  static updateUserState(action) {
    return ActionUtility.createAction(UsersAction.UPDATE_USER_STATE, action);
  }

  static getUserQuota(callback) {
    return ActionUtility.createAction(UsersAction.GET_USER_QUOTA, callback);
  }

  static getGroupsList(callback) {
    return ActionUtility.createAction(UsersAction.GET_GROUPS_LIST, callback);
  }

  static createGroup(groupData, callback) {
    return ActionUtility.createAction(UsersAction.CREATE_GROUP, {groupData, callback});
  }

  static updateGroup(groupId, groupData, callback) {
    return ActionUtility.createAction(UsersAction.EDIT_GROUP, {groupId, groupData, callback});
  }

  static deleteGroup(groupId, callback) {
    return ActionUtility.createAction(UsersAction.DELETE_GROUP, {groupId, callback});
  }
  static deleteGroupForever(groupId, callback) {
    return ActionUtility.createAction(UsersAction.DELETE_GROUP_FOREVER, {groupId, callback});
  }

  static activateGroup(groupId, callback) {
    return ActionUtility.createAction(UsersAction.ACTIVATE_GROUP, {groupId, callback});
  }

  static getPermissionsAll(callback) {
    return ActionUtility.createAction(UsersAction.GET_PERMISSIONS_ALL, callback);
  }

  static getAdminList(data) {
    //console.log('action', data);
    return ActionUtility.createAction(UsersAction.GET_ADMIN_LIST, data);
  }

  static createAdmin(user) {
    return ActionUtility.createAction(UsersAction.CREATE_ADMIN, user);
  }

  static editAdmin(editedUser, callback) {
    return ActionUtility.createAction(UsersAction.EDIT_ADMIN, {editedUser, callback});
  }

  static editAdminName(id, newInfo, currentPage = 0, callback) {
    return ActionUtility.createAction(UsersAction.EDIT_ADMIN_NAME, {id, newInfo, currentPage, callback});
  }

  static deleteAdmin(userData, callback) {
    return ActionUtility.createAction(UsersAction.DELETE_ADMIN, {userData, callback});
  }

}
