import { lazy } from "react";

import MainLayout from "../layouts/MainLayout";
import LoginLayout from "../layouts/LoginLayout";

export const ROLES = {
  admin: "ROLE_ADMIN",
  user: "ROLE_USER",
};

export const paths = {
  home: "/",
  users: "/users",
  admins: "/admins",
  investigationsRoot: "/investigations",
  investigations: "/investigations/:iId?/:cId?",
  dashboard: "/dashboard",
  login: "/login",
  logout: "/logout",
  bearer: "/bearer/:token",
  profiler: "/profiler/:id",
  webint: "/webint/:id",
  webintPosts: "/webint/posts/:id",
  avatars: "/amm/avatars",
  avatar: "/amm/avatar/:id",
  alerts: "/alerts",
  audit: "/audit",
  proxy: "/proxy",
  topPosts: "/top-posts",
  userStat: "/user-stat",

  any: "*",
};

const Home = lazy(() => import("../pages/Home")),
  UsersManagement = lazy(() => import("../pages/UsersManagement")),
  AdminsManagement = lazy(() => import("../pages/AdminsManagement")),
  Login = lazy(() => import("../pages/Login")),
  Logout = lazy(() => import("../pages/Logout")),
  Bearer = lazy(() => import("../pages/Bearer")),
  Profiler = lazy(() => import("../pages/Target/Profiler")),
  Webint = lazy(() => import("../pages/Target/Webint")),
  WebintPosts = lazy(() => import("../pages/Target/Webint/FullScreenPosts")),
  Investigations = lazy(() => import("../pages/Investigations")),
  Dashboard = lazy(() => import("../pages/Dashboard")),
  Avatar = lazy(() => import("../pages/Amm/Avatar")),
  Avatars = lazy(() => import("../pages/Amm/Avatars")),
  Alerts = lazy(() => import("../pages/Alerts")),
  Audit = lazy(() => import("../pages/Audit")),
  Proxy = lazy(() => import("../pages/Proxy")),
  TopPosts = lazy(() => import("../pages/TopPosts")),
  UserStat = lazy(() => import("../pages/UserStat")),
  NotFound = lazy(() => import("../pages/NotFoundPage"));

const createRoute = (
  pathname,
  component,
  layout,
  needRole = "",
  name = "",
  nameToDisplay = "",
  mainMenu = false,
  state = {},
  permission = false,
  redirect = false,
) => {
  return {
    location: {
      pathname,
      state,
    },
    name,
    nameToDisplay,
    needRole,
    mainMenu,
    component,
    layout,
    permission,
    redirect,
  };
};

export const Routes = [
  createRoute(paths.home, Home, MainLayout, ROLES.user, "Home", "", false),
  createRoute(
    paths.dashboard,
    Dashboard,
    MainLayout,
    ROLES.user,
    "Dashboard",
    "Dashboard",
    false
  ),
  createRoute(
    paths.profiler,
    Profiler,
    MainLayout,
    ROLES.user,
    "Profiler",
    "Profiler",
    false
  ),
  createRoute(
    paths.users,
    UsersManagement,
    MainLayout,
    ROLES.admin,
    "Users",
    "User Management",
    false
  ),
  createRoute(
    paths.admins,
    AdminsManagement,
    MainLayout,
    ROLES.admin,
    "Admins",
    "Admins Management",
    false
  ),
  createRoute(
    paths.investigations,
    Investigations,
    MainLayout,
    ROLES.user,
    "Investigations",
    "Investigations",
    false
  ),
  createRoute(
    paths.investigationsRoot,
    Investigations,
    MainLayout,
    ROLES.user,
    "Investigations",
    "Investigations",
    true,
    {},
    false,
    //true,
  ),
  createRoute(
    paths.avatars,
    Avatars,
    MainLayout,
    ROLES.user,
    "Avatars",
    "Avatars",
    true,
    {},
    'module.avatars',
  ),
  createRoute(
    paths.avatar,
    Avatar,
    MainLayout,
    ROLES.user,
    "Avatar",
    "Avatar",
    false
  ),
  createRoute(
    paths.webint,
    Webint,
    MainLayout,
    ROLES.user,
    "Webint",
    "Webint",
    false
  ),
  createRoute(
    paths.webintPosts,
    WebintPosts,
    MainLayout,
    ROLES.user,
    "Webint Posts",
    "Webint Posts",
    false
  ),
  createRoute(
    paths.alerts,
    Alerts,
    MainLayout,
    ROLES.user,
    "Alerts",
    "Alerts",
    true,
    {},
    'module.alerts',
  ),
  createRoute(
    paths.audit,
    Audit,
    MainLayout,
    ROLES.user,
    "Audit",
    "Audit",
    false
  ),
  createRoute(paths.proxy, Proxy, MainLayout, ROLES.user, "Proxy", "Proxy"),
  createRoute(paths.topPosts, TopPosts, MainLayout, ROLES.admin, "Top posts", "Top posts"),
  createRoute(paths.userStat, UserStat, MainLayout, ROLES.admin, "Users Statistics", "Users Statistics"),
  createRoute(paths.login, Login, LoginLayout),
  createRoute(paths.logout, Logout),
  createRoute(paths.bearer, Bearer),
  createRoute(paths.any, NotFound, MainLayout, ROLES.admin),
];