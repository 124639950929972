import moment from "moment";
import config from "../../config/config";

export const transformInvestigations = (data, user) => {
  const invest = [];
  Object.keys(data).forEach((key) => {
    data[key].invList.forEach((el) => invest.push(el));
  });
  return {
    array: invest.map((el) => {
      return {
        ...el,
        key: el.invNumber,
        title: el.invName,
        description: el.createdBy,
      };
    }),
    targetKeys: data[user.userName].invList.map((el) => el.invNumber),
  };
};

export const transformInvestToEdit = (dataObj) => {
  return dataObj.array
    .filter((el) => dataObj.targetKeys.includes(el.key))
    .map((el) => `${el.invNumber}`);
};

export const getQuotaDate = (payloadDate, initialDate) => {
  if (payloadDate) {
    return moment(payloadDate).format("YYYY-MM-DD");
  }
  if (initialDate) {
    return moment(initialDate).format("YYYY-MM-DD");
  } else {
    return null;
  }
};

export const transformUserQuotaFromAPI = formValues => {
  const userData = {
    quota_dailyQuota: formValues?.quota?.dailyQuota || 0,
    quota_isDaily: !!formValues?.quota?.isDaily,

    quota_quotaProfiler_cases: formValues?.quota?.quotaProfiler?.cases?.maxQuota,
    quota_quotaProfiler_lookup: formValues?.quota?.quotaProfiler?.lookup?.maxQuota,
    quota_quotaProfiler_crawler: formValues?.quota?.quotaProfiler?.crawler?.maxQuota,
    quota_quotaProfiler_isForever: !!formValues?.quota?.quotaProfiler?.isForever,
    quota_quotaProfiler_interval: [
      formValues?.quota?.quotaProfiler?.validFrom ? moment(formValues?.quota?.quotaProfiler?.validFrom, 'DD-MM-YYYY') : null,
      formValues?.quota?.quotaProfiler?.expireBy ? moment(formValues?.quota?.quotaProfiler?.expireBy, 'DD-MM-YYYY') : null,
    ],

    quota_quotaWebint_cases: formValues?.quota?.quotaWebint?.cases?.maxQuota,
    quota_quotaWebint_crawler: formValues?.quota?.quotaWebint?.crawler?.maxQuota,
    quota_quotaWebint_monitoring: formValues?.quota?.quotaWebint?.monitoring?.maxQuota,
    quota_quotaWebint_isForever: !!formValues?.quota?.quotaWebint?.isForever,
    quota_quotaWebint_interval: [
      formValues?.quota?.quotaWebint?.validFrom ? moment(formValues?.quota?.quotaWebint?.validFrom, 'DD-MM-YYYY') : null,
      formValues?.quota?.quotaWebint?.expireBy ? moment(formValues?.quota?.quotaWebint?.expireBy, 'DD-MM-YYYY') : null,
    ],

  }
  //console.log('transformUserQuotaFromAPI', userData)

  return userData;
}

export const transformGroupFromAPI = (formValues) => {
  const groupData = {
    groupName: formValues?.groupName,
    description: formValues?.description,
    maxUsersLimit: formValues?.maxUsersLimit,
    concurrentUsersLimit: formValues?.concurrentUsersLimit,

    permissions: [...formValues?.permissions?.map(p => p.name)],

    quota_dailyQuota: formValues?.quota?.dailyQuota || 0,
    quota_isDaily: !!formValues?.quota?.isDaily,

    quota_quotaProfiler_cases: formValues?.quota?.quotaProfiler?.cases?.maxQuota,
    quota_quotaProfiler_lookup: formValues?.quota?.quotaProfiler?.lookup?.maxQuota,
    quota_quotaProfiler_crawler: formValues?.quota?.quotaProfiler?.crawler?.maxQuota,
    quota_quotaProfiler_isForever: !!formValues?.quota?.quotaProfiler?.isForever,
    quota_quotaProfiler_interval: [
      formValues?.quota?.quotaProfiler?.validFrom ? moment(formValues?.quota?.quotaProfiler?.validFrom, 'YYYY-MM-DD') : null,
      formValues?.quota?.quotaProfiler?.expireBy ? moment(formValues?.quota?.quotaProfiler?.expireBy, 'YYYY-MM-DD') : null,
    ],

    quota_quotaWebint_cases: formValues?.quota?.quotaWebint?.cases?.maxQuota,
    quota_quotaWebint_crawler: formValues?.quota?.quotaWebint?.crawler?.maxQuota,
    quota_quotaWebint_monitoring: formValues?.quota?.quotaWebint?.monitoring?.maxQuota,
    quota_quotaWebint_isForever: !!formValues?.quota?.quotaWebint?.isForever,
    quota_quotaWebint_interval: [
      formValues?.quota?.quotaWebint?.validFrom ? moment(formValues?.quota?.quotaWebint?.validFrom, 'YYYY-MM-DD') : null,
      formValues?.quota?.quotaWebint?.expireBy ? moment(formValues?.quota?.quotaWebint?.expireBy, 'YYYY-MM-DD') : null,
    ],

  }
  ///console.log('transformGroupFromAPI', groupData)

  return groupData;
}


export const transformUserQuotaToSave = (formValues) => {
  let profilerValidFrom = null, profilerValidTo = null;
  let webintValidFrom = null, webintValidTo = null;

  if (formValues?.quota_quotaProfiler_interval && formValues?.quota_quotaProfiler_interval?.length > 0) {
    if (formValues.quota_quotaProfiler_interval[0]) {
      profilerValidFrom = moment(formValues.quota_quotaProfiler_interval[0]).format("YYYY-MM-DD");
    }
    if (formValues.quota_quotaProfiler_interval[1]) {
      profilerValidTo = moment(formValues.quota_quotaProfiler_interval[1]).format("YYYY-MM-DD");
    }
  }
  if (formValues?.quota_quotawebint_interval && formValues?.quota_quotaWebint_interval?.length > 0) {
    if (formValues.quota_quotaWebint_interval[0]) {
      webintValidFrom = moment(formValues.quota_quotaWebint_interval[0]).format("YYYY-MM-DD");
    }
    if (formValues.quota_quotaWebint_interval[1]) {
      webintValidTo = moment(formValues.quota_quotaWebint_interval[1]).format("YYYY-MM-DD");
    }
  }

  const formData = {
    quota: {
      isDaily: !!formValues?.quota_isDaily,
      dailyQuota: formValues?.quota_dailyQuota * 1,
      quotaProfiler: {
        lookup: {
          maxQuota: formValues?.quota_quotaProfiler_lookup * 1,
        },
        crawler: {
          maxQuota: formValues?.quota_quotaProfiler_crawler * 1,
        },
        cases: {
          maxQuota: formValues?.quota_quotaProfiler_cases * 1,
        },
        validFrom: profilerValidFrom,
        expireBy: profilerValidTo,
        isForever: !!formValues?.quota_quotaProfiler_isForever,
      },
      quotaWebint: {
        monitoring: {
          maxQuota: formValues?.quota_quotaWebint_monitoring * 1,
        },
        crawler: {
          maxQuota: formValues?.quota_quotaWebint_crawler * 1,
        },
        cases: {
          maxQuota: formValues?.quota_quotaWebint_cases * 1,
        },
        validFrom: webintValidFrom,
        expireBy: webintValidTo,
        isForever: !!formValues?.quota_quotaWebint_isForever,
      },
    },
  }
  ///console.log('transformUserQuotaToSave', formData);

  return formData;
}

export const transformGroupToSave = (formValues) => {
  let profilerValidFrom = null, profilerValidTo = null;
  let webintValidFrom = null, webintValidTo = null;
  if (formValues?.quota_quotaProfiler_interval && formValues?.quota_quotaProfiler_interval?.length > 0) {
    if (formValues.quota_quotaProfiler_interval[0]) {
      profilerValidFrom = moment(formValues.quota_quotaProfiler_interval[0]).format("YYYY-MM-DD");
    }
    if (formValues.quota_quotaProfiler_interval[1]) {
      profilerValidTo = moment(formValues.quota_quotaProfiler_interval[1]).format("YYYY-MM-DD");
    }
  }
  if (formValues?.quota_quotaWebint_interval && formValues?.quota_quotaWebint_interval?.length > 0) {
    if (formValues.quota_quotaWebint_interval[0]) {
      webintValidFrom = moment(formValues.quota_quotaWebint_interval[0]).format("YYYY-MM-DD");
    }
    if (formValues.quota_quotaWebint_interval[1]) {
      webintValidTo = moment(formValues.quota_quotaWebint_interval[1]).format("YYYY-MM-DD");
    }
  }

  const formData = {
    groupName: formValues?.groupName,
    maxUsersLimit: formValues?.maxUsersLimit * 1,
    concurrentUsersLimit: formValues?.concurrentUsersLimit * 1,
    description: formValues?.description,
    customer: formValues?.customer,
    license: formValues?.license,
    quota: {
      isDaily: !!formValues?.quota_isDaily,
      dailyQuota: formValues?.quota_dailyQuota * 1,
      quotaProfiler: {
        lookup: {
          maxQuota: formValues?.quota_quotaProfiler_lookup * 1,
        },
        crawler: {
          maxQuota: formValues?.quota_quotaProfiler_crawler * 1,
        },
        cases: {
          maxQuota: formValues?.quota_quotaProfiler_cases * 1,
        },
        validFrom: profilerValidFrom,
        expireBy: profilerValidTo,
        isForever: !!formValues?.quota_quotaProfiler_isForever,
      },
      quotaWebint: {
        monitoring: {
          maxQuota: formValues?.quota_quotaWebint_monitoring * 1,
        },
        crawler: {
          maxQuota: formValues?.quota_quotaWebint_crawler * 1,
        },
        cases: {
          maxQuota: formValues?.quota_quotaWebint_cases * 1,
        },
        validFrom: webintValidFrom,
        expireBy: webintValidTo,
        isForever: !!formValues?.quota_quotaWebint_isForever,
      },
    },
    permissions: formValues?.permissions,
  }
  //console.log('transformGroupToSave', formData);

  return formData;
}


export const transformUserPermissions = (userInfo = {}, authInfo = {}) => {
  const permissions = {};
  const {profilerIsActive, webintIsActive, permissions: apiPermissionsData} = userInfo;
  const {roles} = authInfo;
  const apiPermissions = apiPermissionsData.map(p => p.name) || [];

  const isAdmin = roles.includes('ADMIN') || roles.includes('ROLE_ADMIN');
  const isUser = roles.includes('USER') || roles.includes('ROLE_USER');
  const isManager = roles.includes('ROLE_MANAGER');
  const webintTypes = config.webintTaskTypesAvailable ? config.webintTaskTypesAvailable.split(',') : [];

  //modules
  //permissions['module.profiler'] = profilerIsActive;
  permissions['module.profiler'] = isAdmin || apiPermissions.includes("PROFILER");
  //permissions['module.webint'] = webintIsActive;
  permissions['module.webint'] = isAdmin || apiPermissions.includes("WEBINT");
  //permissions['module.avatars'] = isAdmin || config.moduleAvatars;
  permissions['module.avatars'] = isAdmin || (config.moduleAvatars && apiPermissions.includes("AMM"));
  //permissions['module.alerts'] = isAdmin || config.moduleAlerts;
  permissions['module.alerts'] = isAdmin || (config.moduleAlerts && apiPermissions.includes("ALERTS"));
  //permissions['module.top_posts'] = isAdmin || config.moduleTopPosts;
  permissions['module.top_posts'] = isAdmin || (config.moduleTopPosts && apiPermissions.includes("TOP_POSTS"));
  permissions['module.proxy'] = isAdmin || apiPermissions.includes("PROXY_MANAGEMENT");
  permissions['module.audit'] = apiPermissions.includes("AUDIT");

  //header
  permissions['header.new_tab'] = config.headerNewTab;
  permissions['header.languages'] = isAdmin || config.headerLanguages;
  permissions['header.logout'] = isAdmin || config.headerLogout;

  permissions['role.admin'] = isAdmin;
  permissions['role.user'] = isUser;
  permissions['role.manager'] = isManager;

  //profiler
  permissions['profiler.use_avatars'] = config.profilerUseAvatars;
  permissions['profiler.search_single_option'] = config.profilerSearchSingleOption;
  permissions['profiler.add_phone'] = config.profilerAddPhone;
  permissions['profiler.add_source_from_networks'] = config.profilerAddSourceFromNetworks;
  permissions['profiler.use_hydra'] = config.profilerUseHydra;
  permissions['profiler.update_sources'] = isAdmin || config.profilerUpdateSources;
  permissions['profiler.fetch_more_friends'] = config.profilerFetchMoreFriends;
  permissions['profiler.fetch_more_posts'] = config.profilerFetchMorePosts;
  permissions['profiler.post_ai_analysis'] = config.profilerPostAIAnalysis;
  permissions['profiler.show_verified_sign'] = config.profilerShowVerifiedSign;
  permissions['profiler.auto_refresh'] = config.profilerAutoRefresh;
  permissions['profiler.ai_contact_info'] = config.profilerAiContactInfo;

  permissions['avatars.use_default'] = isAdmin && config.avatarsUseDefault;
  permissions['avatars.ai_posts_generator'] = /*isAdmin && */config.avatarsAiPostsGeneration;


  permissions['webint.telegram_get_more_photos'] = config.webintTelegramMorePhotos;
  permissions['webint.getcontact_get_more_tags'] = config.webintGetcontactMoreTags;
  permissions['webint.post_ai_analysis'] = config.webintPostAIAnalysis;
  permissions['webint.collect_reactions'] = config.webintTaskCollectReactions;
  ['groups', 'keywords', 'posts', 'keywords_groups'].forEach(taskType => {
    permissions[`webint.types.${taskType}`] = webintTypes.includes(taskType)
  })


  //browse
  permissions['browse.delete_items'] = isAdmin || config.browseDeleteItems;
  permissions['browse.rename_items'] = isAdmin || config.browseRenameItems;
  permissions['browse.context_menu'] = isAdmin || (config.browseRenameItems || config.browseDeleteItems);

  //investigation
  permissions['investigation.show_creator'] = isAdmin || config.investigationShowCreator;

  //users
  permissions['user.daily_quota'] = config.userDailyQuota;
  permissions['user.rename'] = config.userRename;

  permissions['user.management'] = isAdmin || (isManager && apiPermissions.includes("USER_MANAGEMENT"));
  permissions['user.stats'] = isAdmin || (isManager && apiPermissions.includes("USER_STATS"));
  permissions['user.roles_management'] = isAdmin || (isManager && apiPermissions.includes("USER_MANAGEMENT"));
  permissions['groups.management'] = isAdmin;
  permissions['admin.management'] = isAdmin;


  ///console.log('!!!!!!transformUserPermissions', permissions);


  return permissions;
};