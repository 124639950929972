//investigations/28/443 - prod
import dagre from "dagre";
import { stratify, tree } from 'd3-hierarchy';
import {transformFriendsData} from "../profiler/profilerTransformer";
import ELK from 'elkjs/lib/elk.bundled.js';
import {forceCenter, forceCollide, forceLink, forceManyBody, forceSimulation, forceX, forceY} from "d3-force";
import {quadtree} from "d3-quadtree";
import {profilerMockData as node} from "../../mock/profiler";


const LIMIT_SOURCES = 5;

//internal functions

function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function normalizeNodes(nodes, edges) {
  const nodesIds = nodes.map(({id}) => id);
  const normalizedEdges = edges.filter(({source, target}) => nodesIds.includes(source) && nodesIds.includes(target));

  return {nodes: nodes, edges: normalizedEdges};
}

export function collide() {
  let nodes = [];
  let force = (alpha) => {
    const tree = quadtree(
      nodes,
      (d) => d.x,
      (d) => d.y,
    );

    for (const node of nodes) {
      const r = node?.measured?.width / 2;
      const nx1 = node.x - r;
      const nx2 = node.x + r;
      const ny1 = node.y - r;
      const ny2 = node.y + r;

      tree.visit((quad, x1, y1, x2, y2) => {
        if (!quad.length) {
          do {
            if (quad.data !== node) {
              const r = node?.measured?.width / 2 + quad.data.width / 2;
              let x = node.x - quad.data.x;
              let y = node.y - quad.data.y;
              let l = Math.hypot(x, y);

              if (l < r) {
                l = ((l - r) / l) * alpha;
                node.x -= x *= l;
                node.y -= y *= l;
                quad.data.x += x;
                quad.data.y += y;
              }
            }
          } while ((quad = quad.next));
        }

        return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
      });
    }
  };

  force.initialize = (newNodes) => (nodes = newNodes);

  return force;
}


//export

export const getLayoutedElementsELK = async (nodes, edges) => {
  const elk = new ELK();

  const defaultOptions = {
    'elk.algorithm': 'layered',
    //'elk.layered.spacing.nodeNodeBetweenLayers': 100,
    //'elk.spacing.nodeNode': 50,
  };

  const options = {
    'elk.algorithm': 'org.eclipse.elk.stress',
    'org.eclipse.elk.stress.desiredEdgeLength': 200,
    /*'elk.direction': 'RIGHT',
    'elk.mrtree.spacing.nodeNodeBetweenLayers': 100,
    'elk.spacing.nodeNode': 50,
    'elk.spacing.edgeNode': 50,*/
  }
  const layoutOptions = { ...defaultOptions, ...options };
  const graph = {
    id: 'root',
    layoutOptions: layoutOptions,
    children: nodes,
    edges: edges,
  };

  return await elk.layout(graph).then(({ children }) => {
    // By mutating the children in-place we saves ourselves from creating a
    // needless copy of the nodes array.
    children.forEach((node) => {
      node.position = { x: node.x, y: node.y };
    });
    console.log('children', children);

    return {nodes: children, edges};

  });
};

//D3-hierarchy
export const getLayoutedElementsD3Hierarchy = (nodes, edges, options) => {
  const g = tree();
  if (nodes.length === 0) return { nodes, edges };

  /*const { width, height } = document
    .querySelector(`[data-id="${nodes[0].id}"]`)
    .getBoundingClientRect();*/
  const width = 100, height = 50;
  const hierarchy = stratify()
    .id((node) => node.id)
    .parentId((node) => edges.find((edge) => edge.target === node.id)?.source);
  const root = hierarchy(nodes);
  const layout = g.nodeSize([width * 2, height * 2])(root);

  return {
    nodes: layout
      .descendants()
      .map((node) => ({ ...node.data, position: { x: node.x, y: node.y } })),
    edges,
  };
};

//D3-force
export const getLayoutedElementsD3Force = (nodes, edges, options) => {
  if (nodes.length === 0) return { nodes, edges };
  const copyEdges = JSON.parse(JSON.stringify(edges));

  const nodesToPrepare = nodes.map((node) => ({
    ...node,
    x: node.position.x,
    y: node.position.y,
    width: 150,
    height: 100,
  }));
  const simulation = forceSimulation(nodesToPrepare)
    .force(
      'link',
      forceLink(edges)
        .id((d) => d.id)
        .strength(0.05)
        .distance(100),
    )

    /*.force("charge", forceManyBody())
    .force('collide', forceCollide().radius(25).strength(10))
    .force("x", forceX())
    .force("y", forceY())*/

    .force('collide', forceCollide().radius(25).strength(10))
    .force('charge', forceManyBody().strength(10))
    .force('center', forceCenter(700, 400))
    .force('charge', forceY(10).strength(0.1))


    //.force('collide', collide())
    .alphaTarget(0.05)
    .stop()


    /*.force('charge', forceManyBody().strength(-1000))
    .force('x', forceX().x(0).strength(0.05))
    .force('y', forceY().y(0).strength(0.05))*/
    //.force('collide', collide())
    //.alphaTarget(0.05)

  const changedNodes = simulation.tick(10).nodes().map(node => {
    return {
      ...node,
      position: { x: node.x, y: node.y },
    }
  });


  console.log('simulation', changedNodes);
  console.log('new tree', { nodes: changedNodes, edges: copyEdges });
  return { nodes: changedNodes, edges };
};

//Dagre
export const getLayoutedElements = (nodes, edges, direction = 'TB') => {

  ///filter only visible nodes
  nodes = nodes.filter(node => !node.hidden);
  edges = edges.filter(edge => !edge.hidden);
  //console.log('nodes dagre', nodes);


  //return getLayoutedElementsELK(nodes, edges);
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 252;
  const nodeHeight = 10;

  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({
    rankdir: direction,
    nodesep: 33,
    //ranker: 'tight-tree',
    //align: 'UL',
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: node?.customHeight || nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const newNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const newNode = {
      ...node,
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      position: node?.fixPosition
        ? {...node.position}
        : {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - (nodeWithPosition?.customHeight || nodeHeight) / 2,
        },
    };

    return newNode;
  });

  return { nodes: newNodes, edges };
};

const prepareSearchItems = searchProgress => {
  //collect searchItems
  const searchItems = {};
  searchProgress.forEach(({id, searchResults}) => {
    searchItems[id] = {};
    searchResults.forEach(searchResult => {
      if (searchItems[id]?.[searchResult.relatedLookupRequestItemId]) {
        searchItems[id][searchResult.relatedLookupRequestItemId].push(searchResult);
      } else {
        searchItems[id][searchResult.relatedLookupRequestItemId] = [searchResult];
      }
    })
  })
  //console.log('tranformSearchResults', searchItems);
  return searchItems;
}

export const transformNodesData = (
  {
    targetList: targets = [],
    targetLookups: lookups = [],
    targetSearchItems: searchProgress = [],
    targetSources = [],
    addRoot = true,
  }) => {
  const outNodes = [], outEdges = [];
  const searchItems = prepareSearchItems(searchProgress);
  //console.log('preparedSearch Items', searchItems);


  //add root node or not
  if (addRoot) {
    outNodes.push({
      id: 'root',
      position: { x: 0, y: 0},
      customHeight: 155,
      data: {},
      type: 'default',
      hidden: true,
    })
  }


  targets.forEach((target, idx) => {
    outNodes.push({
      id: target.id + '',
      //position: { x: 0, y: idx * 400 },
      position: { x: 0, y: 0},
      customHeight: 155,
      data: {
        target: {...target, photo: target?.photos?.[0]}
      },
      type: 'target',
    })
    if (addRoot) {
      outEdges.push({
        id: `root-${target.id}`,
        source: `root`,
        target: `${target.id}`,
        label: '',
        animated: false,
        hidden: true,
      },)
    }
  })

  //collect phones
  //console.log('lookupd', lookups);
  lookups.forEach(profile => {
    const {lookupRequests, id: targetId} = profile;
    lookupRequests.filter(lr => lr?.lookupPhone).forEach((lr, idx) => {
      outNodes.push({
        id: lr.id + '',
        //position: { x: 200, y: idx * 100 + outNodes.find(n => n.type === 'target' && n.id === profile.id + '').position.y },
        position: { x: 0, y: 0},
        customHeight: 30,
        data: {
          phone: lr.lookupPhone,
          createdBy: lr.createdBy,
          createdDate: lr.createdDate,
          status: lr.status,
        },
        type: 'phone'
      });
      outEdges.push({
        id: `${profile.id}-${lr.id}`,
        source: `${profile.id}`,
        target: `${lr.id}`,
        label: 'phone',
        animated: true
      },)

      const collectedLocations = [];


      lr?.lookupRequestItems?.forEach(({responseItems}) => {
        let posY = 0;
        let keepL = true;

        //collect locations
        responseItems.filter(({locations}) => locations?.length > 0)?.forEach((item, i) => {
          //console.log('locations', lr.id, item.locations);
          item.locations.forEach((location, idx) => {
            collectedLocations.push(location);
          })


        })
      })

      //summarize locations
      if (collectedLocations.length > 0) {
        outNodes.push({
          id: `location-${lr.id}`,
          data: {
            targetId: targetId,
            type: 'locations',
            count: collectedLocations.length,
            locations: collectedLocations,
          },
          //position: { x: 500  + randomIntFromInterval(-10, 10), y: posY * 40 + outNodes.find(n => n.type === 'phone' && n.id === lr.id + '').position.y - 100},
          position: { x: 0, y: 0},
          customHeight: 100,
          type: 'data',
        })
        outEdges.push({
          id: `${lr.id}-location`,
          source: `${lr.id}`,
          target: `location-${lr.id}`,
          label: 'locations',
        })

      }

    });

    //collect emails
    lookupRequests.filter(lr => lr?.lookupEmail).forEach((lr, idx) => {
      outNodes.push({
        id: lr.id + '',
        //position: { x: 200, y: idx * 100 + outNodes.find(n => n.type === 'target' && n.id === profile.id + '').position.y },
        position: { x: 0, y: 0},
        customHeight: 30,
        data: {
          email: lr.lookupEmail,
          createdBy: lr.createdBy,
          createdDate: lr.createdDate,
          status: lr.status,
        },
        type: 'email'
      });
      outEdges.push({
        id: `${profile.id}-${lr.id}`,
        source: `${profile.id}`,
        target: `${lr.id}`,
        label: 'email',
        animated: true
      },)

    });

    //collect passwords
    lookupRequests.filter(lr => lr?.lookupPassword).forEach((lr, idx) => {
      outNodes.push({
        id: lr.id + '',
        position: { x: 0, y: 0},
        customHeight: 30,
        data: {
          password: lr.lookupPassword,
          createdBy: lr.createdBy,
          createdDate: lr.createdDate,
          status: lr.status,
        },
        type: 'password'
      });
      outEdges.push({
        id: `${profile.id}-${lr.id}`,
        source: `${profile.id}`,
        target: `${lr.id}`,
        label: 'password',
        animated: true
      },)

    });

    //collect names
    lookupRequests.filter(lr => lr?.lookupName/* && lr?.rootRequest*/).forEach((lr, idx) => {

      const {lookupRequestItems, rootLookupRequestId = null, ...restLm} = lr;
      let rootRequest = lookupRequests.find(lr => lr.id === rootLookupRequestId);

      //collect sources
      const sources = [];

      if (lr?.source === 'HYDRA') {
        rootRequest = null;
        sources.push({source: lr.source});
      } else {
        if (rootRequest) {
          rootRequest.lookupRequestItems?.forEach(({responseItems}) => {
            responseItems.filter(({names}) => names?.includes(lr.lookupName))?.forEach((item, i) => {
              sources.push(item)
            });
          });
        } else {
          sources.push({source: 'manual'});
        }
      }

      //console.log('sources!', lr.id, lr, sources);

      outNodes.push({
        id: lr.id + '',
        data: {
          ...restLm,
          name: lr.lookupName,
          sources,
        },
        //position: { x: 500  + randomIntFromInterval(-10, 10), y: posY * 40 + outNodes.find(n => n.type === 'phone' && n.id === lr.id + '').position.y - 100},
        position: { x: 0, y: 0},
        //customHeight: lookupRequests.filter(lr => lr?.lookupName).length === idx + 1 ? 40 : 1,
        customHeight: 1,
        type: 'name',
      })
      outEdges.push({
        id: `${lr.id}-${rootRequest ? rootRequest?.id : targetId}`,
        source: `${rootRequest ? rootRequest?.id : targetId}`,
        target: `${lr.id}`,
        label: 'name',
      })

      if (lookupRequests.filter(lr => lr?.lookupName).length === idx + 1) {
        outNodes.push({
          id: `${rootRequest ? rootRequest?.id : targetId}-fake`,
          data: {
            name: lr.lookupName,

          },
          position: { x: 0, y: 0},
          //customHeight: lookupRequests.filter(lr => lr?.lookupName).length === idx + 1 ? 40 : 1,
          customHeight: 50,
          type: 'divider',
          hidden: true,
        })
        outEdges.push({
          id: `${rootRequest ? rootRequest?.id : targetId}-fake`,
          source: `${rootRequest ? rootRequest?.id : targetId}`,
          target: `${rootRequest ? rootRequest?.id : targetId}-fake`,
          label: 'name',
          hidden: true,
        })
      }


      //collect search progress
      let keep = true;
      let allCount = 0;
      const hiddenIds = [];

      lookupRequestItems.forEach((lrI, jdx) => {
        if (searchItems?.[profile.id]?.[lrI.id]) {
          searchItems[profile.id][lrI.id].forEach((searchResult, idx) => {
            if (idx === 0 && keep) {
              outNodes.push({
                id: searchResult.id + '-fake',
                data: {},
                position: { x: 0, y: 0},
                //customHeight: lookupRequests.filter(lr => lr?.lookupName).length === idx + 1 ? 40 : 1,
                customHeight: 1,
                type: 'divider',
                hidden: true,
              })
              outEdges.push({
                id: `${searchResult.id}-fake`,
                source: `${lr.id}`,
                target: `${searchResult.id}-fake`,
                label: '',
                hidden: true,
              })
              keep = false
            }

            allCount++;
            const isHidden = allCount > LIMIT_SOURCES && !searchResult?.isProfileDataAdded;
            if (isHidden) {
              hiddenIds.push(searchResult.id + '')
            }
            //console.log('allCount', allCount, hiddenCount, searchResult.id);

            outNodes.push({
              id: searchResult.id + '',
              data: {
                ...searchResult,
                targetId,
              },
              //position: { x: 500  + randomIntFromInterval(-10, 10), y: posY * 40 + outNodes.find(n => n.type === 'phone' && n.id === lr.id + '').position.y - 100},
              position: { x: 800 + randomIntFromInterval(-100, 100), y: 30 * jdx + randomIntFromInterval(-100, 100), },
              customHeight: 1,
              //fixPosition: true,
              type: 'source',
              hidden: isHidden,
            })
            outEdges.push({
              id: `${searchResult.id}-${lr.id}`,
              source: `${lr.id}`,
              target: `${searchResult.id}`,
              label: 'crawler',
              style: {
                stroke: searchResult?.isProfileDataAdded ? '#90ee90' : '',
              },
              hidden: isHidden,
            })

            const parent = outNodes.find(({id}) => id === lr.id + '');
            if (parent) parent.data.completed = true;

            //collect friends, photos
            if (searchResult?.isProfileDataAdded) {
              const {friends = [], photos = [], contactInfo = [], preferences = []} = targetSources?.find(({targetId: id}) => targetId + '' === id + '');
              //console.log('found,', friends, photos,contactInfo);
              const foundFriends = friends.filter(friend => friend.relatedLookupRequestItemId === searchResult.relatedLookupRequestItemId && friend?.friendOf === searchResult?.profileId);
              const foundPhotos = photos.filter(photo => photo.socialId === searchResult?.profileId && photo.socialNetwork === searchResult.socialNetwork);
              const foundContactInfo = contactInfo.filter(ci => ci.profileId === searchResult?.profileId && ci.sid === searchResult.socialNetwork);

              if (foundFriends.length > 0) {
                outNodes.push({
                  id: `friends-${searchResult.id}`,
                  data: {
                    targetId: targetId,
                    type: 'friends',
                    count: foundFriends.length,
                    friends: foundFriends,
                  },
                  position: { x: 0, y: 0},
                  customHeight: 100,
                  type: 'data',
                })
                outEdges.push({
                  id: `${searchResult.id}-friends`,
                  source: `${searchResult.id}`,
                  target: `friends-${searchResult.id}`,
                  label: 'friends',
                })
              }

              if (foundPhotos.length > 0) {
                outNodes.push({
                  id: `photos-${searchResult.id}`,
                  data: {
                    targetId: targetId,
                    type: 'photos',
                    count: foundPhotos.length,
                    photos: foundPhotos,
                  },
                  position: { x: 0, y: 0},
                  customHeight: 100,
                  type: 'data',
                })
                outEdges.push({
                  id: `${searchResult.id}-photos`,
                  source: `${searchResult.id}`,
                  target: `photos-${searchResult.id}`,
                  label: 'photos',
                })
              }

              if (foundContactInfo.length > 0) {
                outNodes.push({
                  id: `contact-${searchResult.id}`,
                  data: {
                    targetId: targetId,
                    type: 'contactInfo',
                    count: '',
                    contactInfo: foundContactInfo?.[0],
                  },
                  position: { x: 0, y: 0},
                  customHeight: 100,
                  type: 'data',
                })
                outEdges.push({
                  id: `${searchResult.id}-contact`,
                  source: `${searchResult.id}`,
                  target: `contact-${searchResult.id}`,
                  label: 'contact info',
                })
              }
            }

          })
        }
      })
      if (hiddenIds?.length > 0) {
        outNodes.push({
          id: lr.id + '-more',
          data: {
            count: hiddenIds.length,
            ids: hiddenIds,
          },
          position: { x: 0, y: 0, },
          customHeight: 1,
          type: 'moreSource',
        })
        outEdges.push({
          id: `${lr.id}-more-${lr.id}`,
          source: `${lr.id}`,
          target: `${lr.id}-more`,
          label: 'more',
        })
      }
    });

    //collect social profiles
    lookupRequests.filter(lr => lr?.socialProfile).forEach((lr, idx) => {
      const {lookupRequestItems, ...restLm} = lr;
      //console.log('socials', lookupRequestItems, lr.id, searchItems);

      lookupRequestItems.forEach((lrI, jdx) => {
        if (searchItems?.[profile.id]?.[lrI.id]) {
          searchItems[profile.id][lrI.id].forEach(searchResult => {
            outNodes.push({
              //id: searchResult.id + '',
              id: searchResult.id + '',
              data: {
                ...searchResult,
                targetId,
              },
              position: { x: 0, y: 0},
              customHeight: 1,
              //fixPosition: true,
              type: 'source',
            })
            outEdges.push({
              //id: `${searchResult.id}-${targetId}`,
              id: `${searchResult.id}-${targetId}`,
              source: `${targetId}`,
              //target: `${searchResult.id}`,
              target: `${searchResult.id}`,
              label: 'crawler',
              style: {
                stroke: searchResult?.isProfileDataAdded ? '#90ee90' : '',
              }
            })

            const parent = outNodes.find(({id}) => id === lr.id + '');
            if (parent) parent.data.completed = true;

            if (searchResult?.isProfileDataAdded) {
              const {friends = [], photos = [], contactInfo = [], preferences = []} = targetSources?.find(({targetId: id}) => targetId + '' === id + '');
              //console.log('found,', friends, photos,contactInfo);
              const foundFriends = friends.filter(friend => friend.relatedLookupRequestItemId === searchResult.relatedLookupRequestItemId && friend?.friendOf === searchResult?.profileId);
              const foundPhotos = photos.filter(photo => photo.socialId === searchResult?.profileId && photo.socialNetwork === searchResult.socialNetwork);
              const foundContactInfo = contactInfo.filter(ci => ci.profileId === searchResult?.profileId && ci.sid === searchResult.socialNetwork);

              if (foundFriends.length > 0) {
                outNodes.push({
                  id: `friends-${searchResult.id}`,
                  data: {
                    targetId: targetId,
                    type: 'friends',
                    count: foundFriends.length,
                    friends: foundFriends,
                  },
                  position: { x: 0, y: 0},
                  customHeight: 100,
                  type: 'data',
                })
                outEdges.push({
                  id: `${searchResult.id}-friends`,
                  source: `${searchResult.id}`,
                  target: `friends-${searchResult.id}`,
                  label: 'friends',
                })
              }

              if (foundPhotos.length > 0) {
                outNodes.push({
                  id: `photos-${searchResult.id}`,
                  data: {
                    targetId: targetId,
                    type: 'photos',
                    count: foundPhotos.length,
                    photos: foundPhotos,
                  },
                  position: { x: 0, y: 0},
                  customHeight: 100,
                  type: 'data',
                })
                outEdges.push({
                  id: `${searchResult.id}-photos`,
                  source: `${searchResult.id}`,
                  target: `photos-${searchResult.id}`,
                  label: 'photos',
                })
              }

              if (foundContactInfo.length > 0) {
                outNodes.push({
                  id: `contact-${searchResult.id}`,
                  data: {
                    targetId: targetId,
                    type: 'contactInfo',
                    count: '',
                    contactInfo: foundContactInfo?.[0],
                  },
                  position: { x: 0, y: 0},
                  customHeight: 100,
                  type: 'data',
                })
                outEdges.push({
                  id: `${searchResult.id}-contact`,
                  source: `${searchResult.id}`,
                  target: `contact-${searchResult.id}`,
                  label: 'contact info',
                })
              }
            }

          })
        }
      })
    });

  });


  const normalizedNodes = normalizeNodes(outNodes, outEdges);
  return normalizedNodes;
  //return {nodes: outNodes, edges: outEdges};
};

export const mergeNodes = (oldNodes, newNodes, removeMode = false) => {
  const {nodes: oldN, edges: oldE} = oldNodes;
  const {nodes: newN, edges: newE} = newNodes;
  console.log('newNodes, oldNodes', newNodes, oldNodes);

  //console.log('oldN', oldN, newN);

  const nodesMap = new Map();
  oldN.forEach(item => nodesMap.set(item.id, item));
  newN.forEach(item => nodesMap.set(item.id, {...nodesMap.get(item.id), ...item}));
  const mergedNodes = Array.from(nodesMap.values());

  const edgesMap = new Map();
  oldE.forEach(item => edgesMap.set(item.id, item));
  newE.forEach(item => edgesMap.set(item.id, {...edgesMap.get(item.id), ...item}));
  const mergedEdges = Array.from(edgesMap.values());

  //console.log('mergedNodes', mergedNodes);
  if (removeMode) {
    const newNIDs = newN.map(n => n.id);
    const newEIDs = newE.map(n => n.id);
    const finalMergedNodes = mergedNodes.filter(lr => newNIDs.includes(lr.id));
    const finalMergedEdges = mergedEdges.filter(lr => newEIDs.includes(lr.id));
    console.log('finalMergedNodes', newNIDs, finalMergedNodes);
    console.log('finalMergedEdges', newEIDs, finalMergedEdges);
    return {nodes: finalMergedNodes, edges: finalMergedEdges};
  }

  return {nodes: mergedNodes, edges: mergedEdges};
}

export const transformSourceInfo = (lookupRequest, data) => {
  //console.log('transformSourceInfo', lookupRequest, lookupRequest?.relatedLookupRequestItemId)
  const {friends, photos, ...rest} = data;
  const {content: friendsList} = friends;
  const friendsData = transformFriendsData(friendsList.filter(friend => friend.relatedLookupRequestItemId === lookupRequest.relatedLookupRequestItemId).slice(0, 12));
  const photosData = photos.filter(photo => photo.socialId === lookupRequest?.profileId)

  //console.log('friendslist', lookupRequest, friendsData, rest);

  return {
    friends: friendsData,
    photos: photosData,
    ...rest,
  };
}

export const getTargetByNodeId = (nodes = [], nodeId) => {
  return nodes?.find(node => node.id === nodeId)?.data?.target;
}