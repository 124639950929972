export const selectUserList = (state) => state.users.users;

export const selectUserTotalPages = (state) => state.users.pageCount;

export const selectUserListSorted = (state) => state.users.sorted;

export const selectUserListSortBy = (state) => state.users.sortBy;

export const selectUsersCurrentPage = (state) => state.users.currentPage;

export const selectUsersLoading = (state) => state.users.loading;

export const selectUsersTotalElements = (state) => state.users.totalElements;
export const selectUsersPermissions = (state) => state.users.permissions;
export const selectUsersQuotas = (state) => state.users.userQuota;

export const selectGroups = (state) => state.users.groups;
export const selectActiveGroup = (state) => state.users.activeGroup;
