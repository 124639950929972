import ActionUtility from "../ActionUtility";

export default class AuthAction {

  static LOGIN_INIT = "AuthAction.LOGIN_INIT";
  static LOGIN_ERROR = "AuthAction.LOGIN_ERROR";
  static LOGIN_SUCCESS = "AuthAction.LOGIN_SUCCESS";
  static BEARER_LOGIN_INIT = "AuthAction.BEARER_LOGIN_INIT";
  static LOGOUT = "AuthAction.LOGOUT";
  static GET_CREDENTIALS = "AuthAction.GET_CREDENTIALS";

  static loginInit(username, password) {
    return ActionUtility.createAction(AuthAction.LOGIN_INIT, { username, password });
  }

  static loginError(error, errorMessage) {
    return ActionUtility.createAction(AuthAction.LOGIN_ERROR, { error, errorMessage });
  }

  static loginSuccess(userData) {
    return ActionUtility.createAction(AuthAction.LOGIN_SUCCESS, userData);
  }

  static bearerLoginInit(token) {
    return ActionUtility.createAction(AuthAction.BEARER_LOGIN_INIT, token);
  }

  static logout(username, callback) {
    return ActionUtility.createAction(AuthAction.LOGOUT, {username, callback});
  }

  static getCredentials(callback) {
    return ActionUtility.createAction(AuthAction.GET_CREDENTIALS, callback);
  }

}